@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto:700&display=swap&subset=latin-ext');
@import './bootstrap.css';
* {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-style: normal;
  box-sizing: border-box;
}

body {
  margin: 0!important;
  overflow-y: scroll!important;
}

h1 {
  &:is(.center) {
    text-align: center;
    &:is(.construction) {
      color: #1d3c74;
      font-weight: 900;
      font-size: xx-large;
    }
  }
}

h2,h3,h4,h5,h6 {
  font-weight: 600;
  &:is(.center) {
    text-align: center;
  }
}

img {
  filter: grayscale(0.9);
}


button.lang {
  background: transparent;
  border: 2px solid #222222;
  margin: 20px 5px 0px 5px;
  font-weight: 600;
  font-family: 'Roboto';
  cursor: pointer;
  &:hover {
    background: black;
    color: white;
  }
  &:active, &:focus {
    color: white;
    background: black;
  }
}

.menu {
  a {
    font-family: 'Roboto';
    font-weight: 700;
    color: #222222;
    text-transform: uppercase;
    font-size: 14px;
    padding: 5px;
  }
}

.products {
  .packshot {
    cursor: pointer;
  }
  ul {
    display: inline-block;
    padding-left: 5px;
    li {
      min-height: 32px;
    }
  }
  @media screen and (max-width: 920px) {
    ul {
      padding-top: 20px;
      padding-left: 25px;
    }
  }
  
  .klos {width: 32px;}
}
.App {
  position: relative;
  text-align: center;
  min-height: 100vh;
  width: 100%;
  @media screen and (max-width: 920px) {
    .chips {
      display: none;
    }
  }
  .chips {
    position: fixed;
    opacity: 0.7;
    bottom: 0;
    width: calc(50% - 240px);
    img {
      max-width: 100%;
    }
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
}

.App-logo {
  max-width: 320px;
  height: auto;
  pointer-events: none;
  margin-top: 50px;
}

.App-header, .App-content {
    background-color: transparent;
    max-width: 480px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.about {
  width: 100%;
}
#contact {
  width: 100%;
}
.App-content {
    text-align: left;
    padding-bottom: 40px;
    .dots-2 {
      height: 4px;
      width: 100%;
      background-image: url('./static/dots_2@2x.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    ul {
      margin: 0;
    }
    img {
      max-width: 100%;
    }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu {
  margin-top: 40px;
  height: 80px;
  width: 320px;
  ul { padding: 6px 10px 10px 10px; margin: 0; }
  img {
    height: 10px;
    margin: 0 10px;
  }
  .dots {
    height: 4px;
    width: 100%;
    background-image: url('./static/dots_1@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.fslightbox-container {
  background: linear-gradient(rgba(255, 255, 255, 0.9),#fff 1810%)!important;
}
